import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Hero from "../components/hero";
import HorizontalTestimonial from "../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../components/hero-end";

const IntrovertsCoachingPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "16.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				liam: file(relativePath: { eq: "testimonials/liam-atkins.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				kevin: file(relativePath: { eq: "testimonials/kevin-hinton.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				pierreB: file(relativePath: { eq: "testimonials/Pierre Bauzee.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				daniel: file(
					relativePath: { eq: "testimonials/daniel-brookbank.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				benG: file(relativePath: { eq: "testimonials/Ben Gottlieb.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				shaun: file(relativePath: { eq: "testimonials/shaun-stevens.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const shaun = data.shaun.childImageSharp.gatsbyImageData;
	const liam = data.liam.childImageSharp.gatsbyImageData;
	const benG = data.benG.childImageSharp.gatsbyImageData;
	const kevin = data.kevin.childImageSharp.gatsbyImageData;
	const title = "Introverts | Business & Executive Coaching in London";
	const description =
		"For introverted business owners, the inclination often centres around delving deep into the nuances of your craft or service, focusing on perfecting every detail. However, to truly succeed in the competitive business world, it's equally important to master strategic business management.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const pierreB = data.pierreB.childImageSharp.gatsbyImageData;

	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Introverts Executive Coaching in London",
				item: {
					url: `${siteUrl}/introverts`,
					id: `${siteUrl}/introverts`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/introverts`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Introverts Executive Coaching in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business & Executive Coach for Introverts"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<p>
								For introverted business owners, the inclination often centres
								around delving deep into the nuances of your craft or service,
								focusing on perfecting every detail. However, to truly succeed
								in the competitive business world, it's equally important to
								master strategic business management. This includes planning for
								sustainable growth, refining operational processes, and ensuring
								long-term viability in a competitive marketplace.
							</p>
							<p>
								A business and executive coach is an invaluable asset for
								introverts in business. Working with a coach provides you with
								the customised guidance needed to refine your business model and
								implement effective strategies that boost profitability and
								generate sustainable growth. Coaches are crucial in helping you
								direct your efforts towards the essential elements of your
								business, optimising operations, and aligning your offerings
								with market demands to stay ahead of industry trends.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Bespoke and Personalised Coaching for Introverts
							</h3>
							<p>
								Introverted professionals often possess deep focus and attention
								to detail but may find the outward-facing aspects of running a
								business, such as networking, self-promotion, and assertive
								marketing and ‘selling’ challenging. Also setting appropriate
								pricing, achieving profitability, developing new products or
								services, and crafting effective marketing strategies that don't
								compromise your comfort zone.
							</p>
							<p>
								I am a ‘high scoring’ introvert in personality tests. With over
								forty years of business experience and a focused specialisation
								in business and executive coaching for the past seven years, I
								have all the skills to help and support introverted
								professionals. Our coaching sessions are designed to tackle
								specific challenges such as leveraging new technologies,
								adapting to changing consumer preferences, and navigating the
								dynamic demands of today's market. We'll work together to create
								strategies that not only sustain but also expand your business
								in a way that respects your personal and professional style.
							</p>
							<p>
								Let’s collaborate to ensure your business thrives, empowering
								you to step confidently into both familiar and new market spaces
								while honouring your introverted nature. Whether you’re looking
								to refine your current approach or explore entirely new avenues,
								I'm here to help navigate this journey with you through business
								and executive coaching.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								How Business Coaching can bring light to your business
							</h3>

							<p>
								For introverted business professionals, mastering the art of
								entrepreneurship involves more than just excellent operational
								skills, it requires turning inward strengths into outward
								success. The transition from developing individual skills to
								running a profitable and sustainable business is a journey where
								a business coach can be particularly valuable, especially one
								who appreciates the distinct dynamics that introverts face in
								the business world.
							</p>
							<p>
								Imagine transforming your business with strategies that resonate
								with your introverted nature while meeting your professional
								ambitions:
							</p>
							<ul className="pl-4">
								<li>
									<strong>Develop a Targeted Niche Strategy: </strong>Tailor
									your marketing efforts to connect authentically with your
									ideal audience, fostering deep, meaningful interactions that
									align with your preference for substantial communication.
								</li>
								<li>
									<strong>Expand Your Offerings: </strong>Explore adding
									services that align with your quiet, focused work style, such
									as consulting or personalised client sessions, which allow you
									to operate effectively without the demand for constant social
									interaction.
								</li>
								<li>
									<strong>Optimise Pricing: </strong>With a business coach's
									guidance, fine-tune your pricing structure to reflect the
									quality and distinctiveness of your services.
								</li>
								<li>
									<strong>Strategically Expand Your Team: </strong>Learn how to
									thoughtfully grow your team in a manner that supports your
									projects and enhances your operational capacity without
									overwhelming yourself. This approach allows for a balanced
									expansion that respects your need for calm and controlled
									growth.
								</li>
							</ul>
							<p>
								Engaging with a business coach who understands the unique needs
								and strengths of introverted professionals can provide you with
								the insights and strategies necessary to navigate the
								complexities of business.{" "}
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">So, what’s been stopping you?</h2>
							<p>
								As an introvert, do you find yourself overwhelmed by the
								constant pressure of deadlines and client demands, making it
								difficult to focus on strategic growth? If the thought of
								expanding your services, venturing into new markets, or revising
								your pricing structures for better profitability feels
								uncomfortable, you're certainly not alone.
							</p>
							<p>
								Here's how partnering with a specialised Business & Executive
								Coach for introverts can empower you to navigate these
								challenges with confidence:
							</p>
							<ul className="pl-4">
								<li>
									<strong>Strategic Expansion: </strong>Gain personalised
									guidance on how to expand your service offerings and discover
									new market opportunities that resonate with your unique
									creative vision.
								</li>
								<li>
									<strong>Pricing Mastery: </strong>Receive expert advice on
									refining your pricing strategies to accurately reflect the
									value of your work and boost your profitability and
									self-confidence.
								</li>
								<li>
									<strong>Innovative Product Development: </strong>Get support
									in enhancing your existing services or developing new,
									innovative offerings that distinguish you from your
									competitors.
								</li>
								<li>
									<strong>Effective Team Dynamics: </strong>Learn strategies to
									build and maintain a supportive team that aligns with your
									professional objectives and complements your business.
								</li>
							</ul>
							<p>
								Now is the ideal time to address these challenges with the
								support of an experienced, impartial, and confidential business
								and executive coach for introverts. This partnership is about
								embracing your introverted nature while pushing the boundaries
								of your business potential.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Empowering your business with coaching
							</h2>
							<p>
								As an experienced business and executive coach with a deep
								understanding of the challenges faced by introverted
								professionals in competitive industries, I offer personalised
								coaching tailored to your specific needs. Partnering with me
								opens the door to focused sessions that are designed to boost
								both your personal growth and professional development. We will
								navigate the intricacies of your industry together, ensuring you
								remain ahead of trends and developments.
							</p>
							<p>
								For introverts, leading and managing teams may present unique
								challenges, especially in environments that demand constant
								interaction and visibility. In our sessions, we'll develop
								strategies that enhance your leadership skills in a way that
								feels authentic to your introverted nature. We'll also work
								together to craft strategic business plans that align with your
								goals and reflect your unique strengths, helping you carve a
								strong position in your industry.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">Asking the right questions</h2>

							<p>
								As an executive coach, my job centers around asking the right
								questions and carefully listening to what you say, tapping into
								the true sentiment behind your words and your thought process.
							</p>
							<p>
								I learned how to REALLY listen to my clients in a
								non-judgemental way during my 5 years of counselling and
								psychotherapy training.
							</p>
							<p>
								Executive coaching is all about listening properly without
								judging, I will uncover and support you with any false
								assumptions, fears and life-limiting beliefs. These are the
								things that may be holding you back, like a lack of confidence,
								imposter syndrome etc.
							</p>
							<p>
								Executive coaching like this can help you manage these thoughts
								and feelings, so they no longer impact your personal or
								professional life, while working alongside you to gain focus and
								make a significant impact on your life and business.
							</p>
							<p>
								Empowering you to think about the reasons ‘why you can’, rather
								than ‘why you can’t.’
							</p>
							<p>
								Let’s decide what you want, make a plan and use coaching to make
								it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">About me as a business coach</h2>
							<p>
								As a business coach specialising in supporting introverted
								entrepreneurs, my approach is centred on empowering you not only
								to excel in your business but also to ensure that your
								operations are profitable and sustainable. Our coaching sessions
								provide a secure and confidential environment where you can
								comfortably discuss and refine your business strategies in
								depth.
							</p>
							<p>
								The reality is that introverts can make excellent entrepreneurs.
								I am a high-scoring introvert on personality tests and run a
								busy and profitable coaching business. While it may seem that
								the business world is tailored for extroverts, this is simply
								not the case. Introverts bring unique strengths to
								entrepreneurship, such as deep thinking, strong focus, and great
								listening skills, which are invaluable in the business realm.
							</p>
							<p>
								If you're ready to elevate your business and harness your
								introverted strengths in a way that aligns with your personal
								and professional goals, let's connect and start this
								transformational journey together. Together, we can tailor a
								path that not only meets your needs but also amplifies your
								unique capabilities as an introverted leader.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Reviews from my business and executive coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						showLinkedIn="d-none"
						name="Shaun Stevens. Movo Insurace Brokers Ltd."
						review="I have used Jason as business coaching mentor and he has been absolutely superb. He always managed to get me to think outside the box and see things from another perspective. I would not hesitate to recommend Jason to anybody needing business advice!!"
						profilePic={shaun}
					/>

					<HorizontalTestimonial
						name="Kevin Hinton – kITman Limited."
						review="I have used Jason as a Business Coach. Jason is unique in the fact that he actually listens to what the business owner requires. And does not work from a preformed business builder script.Along with his knowledge in personal psychology, he can identify problems that can be holding the business back. I can highly recommend using Jason – to develop your business. "
						linkedIn="https://www.linkedin.com/in/kevin-hinton-b7a28318/"
						profilePic={kevin}
					/>

					<HorizontalTestimonial
						name="Liam Atkins Copywriting."
						review="If you're considering working with a business coach, stop thinking about it, just speak to Jason. In every session you walk away with another tangible piece of advice or action point that will immediately improve your business. I'd definitely recommend him. Thanks Jason!"
						showLinkedIn="d-none"
						profilePic={liam}
					/>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Ready to work towards that positive future?
							</h2>
							<p>
								Unfortunately, though, none of us have a crystal ball. I can’t
								tell you what life will be like if you do, take that job, wake
								up filled with confidence, or grow your business tenfold - but
								what I can tell you is...
							</p>
							<p>If you do nothing, nothing will change.</p>
							<p>
								On the other hand, it’s amazing what we can achieve together
								when we use coaching to focus on what you really want.
							</p>
							<p>
								So, with nothing to lose - why not book a introductory call with
								me?
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Let's schedule a discovery call together</h3>
							<p>
								Why not schedule a discovery call with me? This relaxed
								conversation is the perfect chance for us to get acquainted and
								delve into how personalised business and executive coaching can
								surpass your professional goals.
							</p>
							<p>
								Together, we'll concentrate on amplifying your business skills
								while respecting your introverted nature, ensuring that your
								business excels in both operational and strategic dimensions.
							</p>
							<p>
								Let’s explore how you can leverage your natural tendencies as an
								introvert to turn your professional ambitions into a successful
								business reality.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default IntrovertsCoachingPage;
